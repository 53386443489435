/* Header.css */
.nav-link-active {
  border-bottom: 2px solid rgb(31,115,252);
  padding-bottom: 2px;
  font-weight: bold;

  }
  
.nav-link:hover {
  border-bottom: 2px solid currentColor;
  padding-bottom: 1px;
  font-weight: bold;

  }
  